import "lazysizes";
import "picturefill";

// スムーススクロール・ページトップ
$('a[href^="#"]').click(function () {
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  let position;
  if (window.matchMedia("(max-width: 835px)")) {
    console.log("test1");
    position = target.offset().top - 60;
  } else {
    console.log("test2");
    position = target.offset().top;
  }
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

// アコーディオン
$(".js-menu__item__link").each(function () {
  $(this).on("click", function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

// 言語選択
$(".header__language").on("click", function () {
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
  } else {
    $(this).addClass("is-active");
  }
});

// ヘッダーメニューSP
$(".header__spBtn").on("click", function () {
  // $('html').toggleClass('scroll-prevent')
  if ($(".header__menu--sp").hasClass("is-active")) {
    $(this).removeClass("is-active");
    $(".header__menu--sp").removeClass("is-active");
  } else {
    $(this).addClass("is-active");
    $(".header__menu--sp").addClass("is-active");
  }
});
// ヘッダーメニューPC
$(".side__menuBtn").on("click", function () {
  // $('html').toggleClass('scroll-prevent')
  if ($(".header__menu--pc").hasClass("is-active")) {
    $(this).removeClass("is-active");
    $(".header__menu--pc").removeClass("is-active");
  } else {
    $(this).addClass("is-active");
    $(".header__menu--pc").addClass("is-active");
  }
});

//マウスが置かれたらclass「on_mouse」を追加
$(".topMv")
  .on("mouseover", function () {
    $(".topMv__cursor").addClass("on-mouse");
  })
  .on("mouseout", function () {
    $(".topMv__cursor").removeClass("on-mouse");
  });
//マウスの位置にあわせて画像のCSSを指定
function mouseMoveMv(e) {
  $(".topMv__cursor").css({
    top: e.clientY,
    left: e.clientX,
  });
}
$(".topMv").on("mousemove", mouseMoveMv);

//マウスが置かれたらclass「on_mouse」を追加
$(".topContact")
  .on("mouseover", function () {
    $(".topContact__cursor").addClass("on-mouse");
  })
  .on("mouseout", function () {
    $(".topContact__cursor").removeClass("on-mouse");
  });
//マウスの位置にあわせて画像のCSSを指定
function mouseMoveContact(e) {
  $(".topContact__cursor").css({
    top: e.clientY,
    left: e.clientX,
  });
}
$(".topContact").on("mousemove", mouseMoveContact);

// ラインスクロールアニメーション
const scrollFunc = function () {
  const target = document.getElementsByClassName("scroll");
  const position = Math.floor(window.innerHeight * 0.9);
  for (let i = 0; i < target.length; i++) {
    let offsetTop = Math.floor(target[i]?.getBoundingClientRect().top);
    if (offsetTop < position) {
      target[i].classList.add("is-active");
    } else {
      target[i].classList.remove("is-active");
    }
  }
};
window.addEventListener("scroll", scrollFunc, false);

const useBgScrollFunc = function () {
  const target = document.getElementById("use");
  const targetBg = document.getElementById("topUseBg");
  const position = Math.floor(window.innerHeight * 0.6);
  let offsetTop = Math.floor(target?.getBoundingClientRect().top);
  if (!targetBg) return;

  if (offsetTop < position) {
    targetBg.classList.add("is-active");
  } else {
    targetBg.classList.remove("is-active");
  }
};
window.addEventListener("scroll", useBgScrollFunc, false);

$(".topProducts__list").slick({
  slidesToShow: 3,
  centerMode: true,
  centerPadding: "23%",
  arrows: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 2000,
  prevArrow: `<div class="slide-arrow slick-prev">
  <span></span>
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" style="">
  <g id="æ¥åå½¢_9" data-name="æ¥åå½¢ 9" fill="none" stroke="#fff" stroke-width="1">
    <path stroke="none" d="M0,31A31,31 0,1,1 62,31A31,31 0,1,1 0,31" class="KAaFrlMK_0"></path>
    <path fill="none" d="M0.5,31A30.5,30.5 0,1,1 61.5,31A30.5,30.5 0,1,1 0.5,31" class="KAaFrlMK_1"></path>
  </g>
<style data-made-with="vivus-instant">
svg {
  transform: rotate(90deg);
}
svg:hover .KAaFrlMK_1 {
  animation: KAaFrlMK_draw 0.4s linear 0s forwards;
}
.KAaFrlMK_0{
  stroke-dasharray:195 197;
  stroke-dashoffset:196;
}
.KAaFrlMK_1{
  stroke-dasharray:192 194;
  stroke-dashoffset:193;
}
@keyframes KAaFrlMK_draw{100%{stroke-dashoffset:0;}}
@keyframes KAaFrlMK_fade{0%{stroke-opacity:1;}94.44444444444444%{stroke-opacity:1;}100%{stroke-opacity:0;}}
</style>
</svg>  
  </div>`,
  nextArrow: `<div class="slide-arrow slick-next">
    <span></span>
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62" viewBox="0 0 62 62" style="">
    <g id="æ¥åå½¢_9" data-name="æ¥åå½¢ 9" fill="none" stroke="#fff" stroke-width="1">
      <path stroke="none" d="M0,31A31,31 0,1,1 62,31A31,31 0,1,1 0,31" class="KAaFrlMK_0"></path>
      <path fill="none" d="M0.5,31A30.5,30.5 0,1,1 61.5,31A30.5,30.5 0,1,1 0.5,31" class="KAaFrlMK_1"></path>
    </g>
  <style data-made-with="vivus-instant">
  svg {
    transform: rotate(90deg);
  }
  svg:hover .KAaFrlMK_1 {
    animation: KAaFrlMK_draw 0.4s linear 0s forwards;
  }
  .KAaFrlMK_0{
    stroke-dasharray:195 197;
    stroke-dashoffset:196;
  }
  .KAaFrlMK_1{
    stroke-dasharray:192 194;
    stroke-dashoffset:193;
  }
  @keyframes KAaFrlMK_draw{100%{stroke-dashoffset:0;}}
  @keyframes KAaFrlMK_fade{0%{stroke-opacity:1;}94.44444444444444%{stroke-opacity:1;}100%{stroke-opacity:0;}}
  </style>
  </svg>  
    </div>`,
  responsive: [
    {
      breakpoint: 835,
      settings: {
        slidesToShow: 1,
        centerPadding: "25%",
      },
    },
  ],
});
// .on("setPosition", function (event, slick, currentSlide) {
//   $(this)
//     .find('.slick-slide[data-slick-index="' + currentSlide + '"]')
//     .addClass("customClass");
// })
// .on(
//   "beforeChange",
//   function (event, slick, currentSlide, nextSlide, prevSlide) {
//     $(this)
//       .find('.slick-slide[data-slick-index="' + (nextSlide - 1) + '"]')
//       .addClass("isActiveFirst");
//     $(this)
//       .find('.slick-slide[data-slick-index="' + (nextSlide - 2) + '"]')
//       .removeClass("isActiveFirst");
//     $(this)
//       .find('.slick-slide[data-slick-index="' + (nextSlide + 1) + '"]')
//       .addClass("isActiveLast");
//     $(this)
//       .find('.slick-slide[data-slick-index="' + nextSlide + '"]')
//       .removeClass("isActiveLast");
//   }
// );

// KOVAXとは
$("#fullpage").fullpage({
  scrollOverflow: true,
  scrollingSpeed: 1500,
});

// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${path}/privacy" target="_blank">` +
        $(this).text() +
        "</a>に同意する"
    );
  });
});

// 写真切り替え
window.addEventListener("load", function () {
  viewSlide(".topAbout__img__slide img");
});
function viewSlide(className, slideNo = -1) {
  let imgArray = document.querySelectorAll(className);
  if (imgArray?.length === 0) return;

  if (slideNo >= 0) {
    //初回以外は現在のスライドを消す
    imgArray[slideNo].style.opacity = 0;
  }
  slideNo++;
  if (slideNo >= imgArray.length) {
    slideNo = 0; //次のスライドがなければ最初のスライドへ戻る
  }
  imgArray[slideNo].style.opacity = 1;
  setTimeout(function () {
    viewSlide(className, slideNo);
  }, 5000);
}

// $(".topMv__cursor").modaal({
//   type: "video",
//   after_open: function () {
//     alert("Before open");
//   },
// });

// $(".topMv__playmovie").modaal({
//   type: "video",
//   after_open: function (e) {
//     console.log({ e });
//   },
// });

// $(".topMv__cursor").modalVideo({
//   autoplay: true,
//   channel: "custom",
//   mute: 1,
//   // url: "/wp-content/themes/kovax/img/kovax_top.mp4",
// });

// $(".topMv__playmovie").modalVideo({
//   autoplay: true,
//   channel: "custom",
//   mute: 1,
//   allowFullScreen: false,
//   // url: "/wp-content/themes/kovax/img/kovax_top.mp4",
// });

$(".feature__video__item").modalVideo({
  autoplay: true,
});

// 商品詳細サムネイル
const thumbnails = document.querySelectorAll(
  ".products__detail__thumbnail__sub__item"
);
let index;
thumbnails.forEach((elm) => {
  elm.addEventListener("click", () => {
    index = [].slice.call(thumbnails).indexOf(elm);
    $(
      `.products__detail__thumbnail__main__item:not(:nth-of-type(${index + 1}))`
    ).removeClass("is-active");
    $(
      `.products__detail__thumbnail__main__item:nth-of-type(${index + 1})`
    ).addClass("is-active");
  });
});

// 検索
$(".search__btn").on("click", function () {
  // $('html').toggleClass('scroll-prevent')
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
    $(".search__menu").removeClass("is-active");
    $(".search__menu__bg").removeClass("is-active");
    $("body").css("overflow-y", "auto");
  } else {
    $(this).addClass("is-active");
    $(".search__menu").addClass("is-active");
    $(".search__menu__bg").addClass("is-active");
    $("body").css("overflow-y", "hidden");
  }
});

const yourFunctionName = () => {
  console.log("submit");
};
